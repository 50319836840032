import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import { useMediaQuery } from '@mui/material';

const FoodSection = ({ images }) => {
  const isiPhone = useMediaQuery('(max-width:800px)');

  return (
    <div>
      <div className='food-section-container' data-aos="zoom-in">
        <div className='food-images'>
          <ImageList
            id='image-list'
            sx={{
              width: '85%',
              height: '100%',
              overflowY: 'hidden'
            }}
            variant="woven"
            cols={isiPhone ? 3 : 5} // Adjust the number of columns based on the device
            gap={12}
          >
            {images.map((image) => (
              <ImageListItem key={image}>
                <img
                  srcSet={`${image}?w=161&fit=crop&auto=format&dpr=2 2x`}
                  src={`${image}?w=161&fit=crop&auto=format`}
                  alt="placeholder"
                  loading="lazy"
                />
              </ImageListItem>
            ))}
          </ImageList>
        </div>
      </div>
    </div>
  );
};

export default FoodSection;

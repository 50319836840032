const MenuSection = ({image, imageTwo}) => {
  return (
    <div className="menu-section-container">
      <div className='menu-one'>
        <img src={image} alt="Placeholder" />
      </div>
      <div className='menu-two'>
        <img src={imageTwo} alt="Placeholder" />
      </div>
    </div>
  );
};

export default MenuSection;

import React, {useState} from "react";
import { GrGallery } from "react-icons/gr";
import { CiLocationOn } from "react-icons/ci";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import { MdRateReview } from "react-icons/md";
import { ImMenu2 } from "react-icons/im";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PhoneRoundedIcon from "@mui/icons-material/PhoneRounded";



const NavBar = ({ onNavButtonClick, image }) => {
  const [openMenu, setOpenMenu] = useState(false);

  const handleHomeClick = () => {
    onNavButtonClick('Home')
  };
  const handleGalleryClick = () => {
    onNavButtonClick('Gallery')
  };
  const handleMenuClick = () => {
    onNavButtonClick('Menu')
  };
  const handleReviewsClick = () => {
    onNavButtonClick('Reviews')
  };
  const handleLocationClick = () => {
    onNavButtonClick('Location')
  };
  const handleContactClick = () => {
    onNavButtonClick('Contact')
  };

  const navBarOptions = [
    {
      text: "Home",
      icon: <HomeIcon/>,
      onClick: handleHomeClick
    },
    {
      text: "Gallery",
      icon: <GrGallery/>,
      onClick: handleGalleryClick
    },
    {
      text: "Menus",
      icon: <MdOutlineRestaurantMenu/>,
      onClick: handleMenuClick
    },
    {
      text: "Reviews",
      icon: <MdRateReview/>,
      onClick: handleReviewsClick
    },
    {
      text: "Location",
      icon: <CiLocationOn/>,
      onClick: handleLocationClick
    },    {
      text: "Contact",
      icon: <PhoneRoundedIcon/>,
      onClick: handleContactClick
    },
  ]
  
  return (
    <nav>
      <div className="navbar-menu-container">
        <ImMenu2 id="navbar-menu-button" onClick={() => setOpenMenu(true)} />
      </div>
      <Drawer open={openMenu} onClose={() => setOpenMenu(false)} anchor="right">
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={() => setOpenMenu(false)}
          onKeyDown={() => setOpenMenu(false)}
        >
          <List>
            {navBarOptions.map((item) => (
              <ListItem key={item.text} disablePadding>
                <ListItemButton onClick={item.onClick}>
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <Divider />
        </Box>
      </Drawer>
      <div className="shop-logo">
        <img src={image} alt="Shop Logo" />
      </div>
      <div className="navbar-buttons">
        <a href="#home" onClick={() => onNavButtonClick('Home')}>HOME</a>
        <a href="#gallery" onClick={() => onNavButtonClick('Gallery')}>GALLERY</a>
        <a href="#menu" onClick={() => onNavButtonClick('Menu')}>MENUS </a>
        <a href="#reviews" onClick={() => onNavButtonClick('Reviews')}>REVIEWS</a>
        <a href="#location" onClick={() => onNavButtonClick('Location')}>LOCATION</a>
        <a href="#contact" onClick={() => onNavButtonClick('Contact')}>CONTACT</a>
      </div>
    </nav>
  );
};

export default NavBar;

import './App.css';
import Aos from 'aos';
import 'aos/dist/aos.css';
import React, { useState, useEffect, startTransition } from 'react';

import AnimationBubbles from './Components/AnimationBubbles';
import NavBar from './Components/NavBar';
import Home from './Components/Home';
import ReviewSectionGoogle from './Components/ReviewSectionGoogle';
import FoodSection from './Components/FoodSection';
import MenuSection from './Components/MenuSection';
import AddressSection from './Components/AddressSection';
import ContactPage from './Components/ContactPage';
// import AboutSection from './Components/AboutSection';
import ContactForm from './Components/ContactForm';

import cafeShopImg1 from './Assets/cafeShopImg1.jpg';
import cafeShopImg2 from './Assets/cafeShopImg2.jpg';
import cafeShopImg3 from './Assets/cafeShopImg3.jpg';
import cafeShopImg4 from './Assets/cafeShopImg4.jpg';
import cafeShopImg5 from './Assets/cafeShopImg5.jpg';
import cafeShopImg6 from './Assets/cafeShopImg6.jpg';
import cafeShopImg7 from './Assets/cafeShopImg7.jpg';
import cafeShopImg8 from './Assets/cafeShopImg8.jpg';
import cafeShopImg9 from './Assets/cafeShopImg9.jpg';
import cafeShopImg10 from './Assets/cafeShopImg10.jpg';
import cafeShopImg11 from './Assets/cafeShopImg11.jpg';
import cafeShopImg12 from './Assets/cafeShopImg12.jpg';
import cafeShopImg13 from './Assets/cafeShopImg13.jpg';
import cafeShopImg14 from './Assets/cafeShopImg14.jpg';
import cafeShopImg15 from './Assets/cafeShopImg15.jpg';

import cafeShopMenu from './Assets/cafeShopMenu.png';


import cafeShopLogo2 from './Assets/cafeShopLogo2.png';
function App() {

  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  const [activeComponent, setActiveComponent] = useState('Home');

  const handleButtonClick = (buttonText) => {
    handleActiveComponent(buttonText)
  };

  const handleActiveComponent = (navOption) => {
    startTransition(() => {
      switch (navOption) {
        case 'Home':
          setActiveComponent("Home");
          break;
        case 'Gallery':
          setActiveComponent("Gallery");
          break;
        case 'Menu':
            setActiveComponent("Menu");
            break;
        case 'Reviews':
          setActiveComponent("Reviews");
          break;
        case 'Location':
          setActiveComponent("Location");
          break;
        case 'Contact':
          setActiveComponent("Contact");
          break;
        default:
          break; // Or some default component
      }
    });
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'Home':
        return <Home/>;
      case 'Gallery':
        return (
          <>
            <FoodSection
              images={[
                cafeShopImg2,
                cafeShopImg3,
                cafeShopImg6,
                cafeShopImg5,
                cafeShopImg8,
                cafeShopImg9,
                cafeShopImg1,
                cafeShopImg11,
                cafeShopImg10,
                cafeShopImg7,
                cafeShopImg4,
                cafeShopImg12,
                cafeShopImg13,
                cafeShopImg14,
                cafeShopImg15
              ]}
            />
          </>
        );
      case 'Menu':
        return <MenuSection image={cafeShopMenu} imageTwo={cafeShopMenu}/>
      case 'Reviews':
        return <ReviewSectionGoogle />;
      case 'Location':
        return (
          <>
            <AddressSection />
          </>
        );
      case 'Contact':
        return (
          <>
           <ContactPage image={cafeShopImg3} phoneNumber={'07716619596'}/>
           <ContactForm/>
          </>
        );
      default:
        return <Home />;
    }
  };

  return (
    <div className='app'>
        {/* <video src={cafeShopVideoBG} autoPlay loop muted playsInline id="background-video"></video> */}
        {/* <div src={backgroundImg} id="background-video"></div> */}
        <AnimationBubbles/>
        <NavBar onNavButtonClick={handleButtonClick} image={cafeShopLogo2}/>
        {renderActiveComponent()}
    </div>
  );
}

export default App;

import React, { useState } from 'react';
import Button from '@mui/material/Button';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({
          apikey: "52877619-1061-4aa3-95ad-4ed09c2e6f74",
          ...formData,
          subject: "New message for All That Cafe",
          from_name: formData.fname
        })
      });
      if (response.ok) {
        alert("Form submitted successfully!");
        setFormData({
          fname: '',
          lname: '',
          email: '',
          message: ''
        });
      } else {
        throw new Error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Error submitting form. Please try again later or try to refresh the page.");
    }
  };

  return (
    <div className='contact-Form-container'>
      <form onSubmit={handleSubmit}>
        <div className='contact-fullname-container'>
          <div className='contact-fname'>
            <label>First name</label>
            <input type="text" id="fname" name="fname" value={formData.fname} onChange={handleChange} required />
          </div>
          <div className='contact-lname'>
            <label>Last name</label>
            <input type="text" id="lname" name="lname" value={formData.lname} onChange={handleChange} required />
          </div>
        </div>
        <div className='contact-email-container'>
          <label>Email</label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className='contact-message-container'>
          <label>Message</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} required />
        </div>
        <div className='bot-checker-container'>
          <label>Please check this box</label>
          <input id='check-box' type="checkbox" name="botcheck" required/>
          <input type="hidden" name="recaptcha_response" id="recaptchaResponse" />
        </div>
        <Button type="submit" variant="contained" sx={{ backgroundColor: 'rgb(10, 109, 1)', marginTop: '2.5%', marginBottom: '2.5%' }}>
          Submit
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;

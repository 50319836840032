import { FaPenAlt } from "react-icons/fa";
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';

const ReviewSectionGoogle = () => {

  const handleReviewButton = () => {
    window.open('https://www.google.com/search?q=all+that+cafe&oq=all+th&gs_lcrp=EgZjaHJvbWUqDggAEEUYJxg7GIAEGIoFMg4IABBFGCcYOxiABBiKBTIPCAEQLhhDGNQCGIAEGIoFMggIAhBFGCcYOzIGCAMQRRhAMgYIBBBFGDkyBggFEEUYQTIGCAYQRRg8MgYIBxBFGDyoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x4876050016cf2f39:0x60d29aae477511c2,1,,,,', '_blank');
  };
  
  return (
    <div>
      <div className='reviews-container'>
        <div className='user-reviews'>
          <iframe 
            src='https://widgets.sociablekit.com/google-reviews/iframe/25406360' 
            frameBorder='0' 
            title='Google Reviews'
            className='google-map-iframe'
          ></iframe>
        </div>
        <div className='review-information'>
          <h2><FaPenAlt style={{fontSize: '80px', color: 'black'}}/></h2> 
          <h3> With positive reviews across the board, All That Cafe doesn't fail to deliver. </h3>
          <h2> Previous Customer? </h2>
          <Button variant="contained" endIcon={<SendIcon />} sx={{backgroundColor: 'rgb(10, 109, 1)'}} onClick={handleReviewButton}>
            Write a review
          </Button>
        </div>
      </div>
    </div>
  );
}
export default ReviewSectionGoogle;

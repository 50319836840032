const AddressSection = () => {

  return (
    <div>
        <div className='address-section-container' data-aos='zoom-in'>
            <div className='address-section-title'>
                <h1>Visit Us</h1>
                <h2>Address</h2>
                <p>132 Southwark St, London SE1 0SW</p>
                <h2>Hours</h2>
                <p>Monday - Sunday <br/> 7am - 8pm</p>
                <h2>Phone</h2>
                <p>07716619596</p>
            </div>
            <div className='google-maps-section'>
                <iframe 
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.3737454068805!2d-0.10444898739935783!3d51.50635887169557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876050016cf2f39%3A0x60d29aae477511c2!2sAll%20That%20Cafe!5e0!3m2!1sen!2suk!4v1714562725393!5m2!1sen!2suk" 
                    width="800" 
                    height="500" 
                    title="Google Map"
                    style={{ border: 0 }}
                    allowFullScreen
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                >
                </iframe>
            </div>
        </div>
    </div>
  );
};

export default AddressSection;

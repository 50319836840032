import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FaPhone } from "react-icons/fa6";
import { IoShareSocialSharp } from "react-icons/io5";

const ContactPage = ({ image, phoneNumber }) => {
  const handleBottomOfPage = () => {
    window.scrollTo({
      top: document.body.scrollHeight / 2,
      behavior: 'smooth' // Smooth scroll animation
    });
  };

  const handleInstagramClick = () => {
    window.open('https://www.instagram.com/allthatcafe/?hl=en'); // Replace 'https://www.instagram.com/' with your Instagram URL
  };

  const handleTikTokClick = () => {
    window.open('https://www.tiktok.com/@allthatcafe'); // Replace 'https://www.tiktok.com/' with your TikTok URL
  };

  return (
    <div>
      <div className='contact-page-container' data-aos='zoom-in'>
        <div className='contact-page-image' >
          <img src={image} alt="Placeholder" />
        </div>

        <div className='contact-information'>
          <Card sx={{ maxWidth: 345 }} style={{ backgroundColor: 'transparent',  boxShadow: 'none' }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <IoShareSocialSharp style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%' }} />
                <h2>Socials</h2>
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                Please drop us a DM on any of the following social media.
              </Typography>
            </CardContent>
            <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
              <Button size="small" onClick={handleInstagramClick}>Instagram</Button>
              <Button size="small" onClick={handleTikTokClick}>Tiktok</Button>
            </CardActions>
          </Card>
          <Card sx={{ maxWidth: 345 }} style={{ backgroundColor: 'transparent',  boxShadow: 'none' }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <FaPhone style={{ display: 'flex', justifyContent: 'center', marginBottom: '5%' }} />
                <h2>Call Us</h2>
              </Typography>
              <Typography variant="body2" color="text.secondary" style={{ display: 'flex', justifyContent: 'center', textAlign: 'center' }}>
                We will only pick up phone calls during work hours.
              </Typography>
            </CardContent>
            <CardActions style={{ display: 'flex', justifyContent: 'center' }}>
              <Button size="small" href={'tel:' + phoneNumber} style={{ fontSize: 'inherit', textTransform: 'none' }}>{phoneNumber}</Button>
            </CardActions>
          </Card>
          <Button variant="contained" endIcon={<ArrowDownwardIcon />} sx={{ backgroundColor: 'rgb(10, 109, 1)', marginTop: '5%' }} onClick={handleBottomOfPage}>
            Send us a message
          </Button>
        </div>

      </div>
    </div>
  );
};

export default ContactPage;

import React from 'react';
import cafeShopVideoBG from '../Assets/cafeShopVideoBG.mp4';
// import cafeShopLogo3 from '../Assets/cafeShopLogo3.jpg';
// import { CiLocationOn } from "react-icons/ci";
import { IoLogoTiktok } from "react-icons/io5";
import { FaInstagram } from "react-icons/fa";
const Home = () => {
  return (
    <div className='home-container'>
      <div className='video-wrapper'>
        <video src={cafeShopVideoBG} autoPlay loop muted playsInline></video>
        {/* <img className='shop-logo' src={cafeShopLogo3} alt="Placeholder"/> */}
      </div>
      <div className='home-information'>
        {/* <CiLocationOn id='CiLocationOn'/> */}
        <h3>132 Southwark St, London SE1 0SW</h3>
        <h3> Monday - Friday:</h3>
        <h3> 8am - 3:30pm</h3>
      </div>
      <div className="socials-buttons">
        <a href="https://www.instagram.com/allthatcafe/?hl=en" target="_blank" rel="noopener noreferrer">
          <FaInstagram className="icon-size" />
        </a>
        <a href="https://www.tiktok.com/@allthatcafe" target="_blank" rel="noopener noreferrer">
          <IoLogoTiktok className="icon-size" />
        </a>
      </div>
    </div>
    
  );
};

export default Home;
